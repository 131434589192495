window.jsPDF = window.jspdf.jsPDF;



// numbers to text

const numberWords = {
    ONES: [
        "",
        "ett",
        "två",
        "tre",
        "fyra",
        "fem",
        "sex",
        "sju",
        "åtta",
        "nio",
    ],
    TENS: [
        "tio",
        "elva",
        "tolv",
        "tretton",
        "fjorton",
        "femton",
        "sexton",
        "sjutton",
        "arton",
        "nitton",
    ],
    TEN: [
        "",
        "tio",
        "tjugo",
        "trettio",
        "fyrtio",
        "femtio",
        "sextio",
        "sjuttio",
        "åttio",
        "nittio",
    ],
    SUFFIX: [
        , ,
        "tusen",
        "miljon",
        "miljard",
        "biljon",
        "biljard",
        "triljon",
        "triljard",
        "kvadriljon",
        "kvadriljard",
    ],

    processNum(num) {
        const realNum = Number(num)
        const numStr = realNum.toString()
        let pos = 0
        let previous = 0
        let numberText = ""

        for (let i = numStr.length - 1; i >= 0; i--) {
            pos++

            switch (pos) {
                case 1:
                    previous = numStr[i]


                    numberText += this.ONES[numStr[i]]

                    break;
                case 2:


                    if (numStr[i] == 1) {
                        numberText = this.TENS[previous]
                    } else {
                        numberText = this.TEN[numStr[i]] + numberText
                    }
                    break;
                case 3:
                    numberText = this.ONES[numStr[i]] + "hundra" + numberText

                    break;

                default:
                    break;
            }

        }

        if (numberText === 'ett') numberText = 'en'
        return numberText;
    },


    splitEveryThird(num) {
        let numString = num.toString()
        let threesArr = []
        let buffer = ""
        let count = 0

        for (let i = numString.length - 1; i >= 0; i--) {

            if (count % 3 === 0 && count !== 0) {
                threesArr.push(buffer)
                buffer = ""
            }

            count++
            buffer = numString[i] + buffer

        }
        threesArr.push(buffer)
        return threesArr.reverse()
    },

    composeNumString(num) {

        let pos = 0
        const thridsArr = this.splitEveryThird(num)
        let thirdsText = []
        let outText = ""

        thridsArr.forEach(third => {
            thirdsText.push(this.processNum(third))
        });

        pos = thirdsText.length

        thirdsText.forEach(third => {

            //stop blank "thirds" SUFFIX from beeing printed
            //handle if third is only 1 to "ett" instead of "en"
            //also refactor below to be more readable

            if (pos > 1) {
                let plur = (third !== 'en' && pos > 2) ? "er" : "";

                if (third === "") {
                    outText += ""
                }
                else if (pos === 2 && third === 'en') {
                    outText += 'et' + this.SUFFIX[pos] + plur + " "
                }
                else if (pos === 2 && third.endsWith('ett')) {
                    outText += third.slice(0, -1) + this.SUFFIX[pos] + plur + " "
                }
                else {
                    outText += third + this.SUFFIX[pos] + plur + " "
                }

            } else if (pos <= 1 && third === 'en') {
                outText += "ett"
            }

            else {
                outText += third
            }
            pos--
        })


        if (outText === 'en') outText = 'ett'

        return outText
    }


}





const diplomaTemplate = {

    doc: new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [130, 180],
        lineHeight: 1,
    }),

    filename: "",

    createMain() {
        const logoSm = new Image()
        const logoLg = new Image()

        logoLg.src = '../assets/images/Large-backdrop-logo@4x.png'
        logoSm.src = '../assets/images/small logo@4x.png'

        this.doc.addFileToVFS("Poppins-Regular.ttf", poppinsRegular)
        this.doc.addFont("Poppins-Regular.ttf", "poppinsRegular", "normal");

        this.doc.addFileToVFS("Poppins-SemiBold.ttf", poppinsSemiBold)
        this.doc.addFont("Poppins-SemiBold.ttf", "poppinsSemiBold", "bold");

        this.doc.addFileToVFS("Butler_Regular.ttf", butlerRegular)
        this.doc.addFont("Butler_Regular.ttf", "butlerRegular", "bold");

        this.doc.setFillColor(246, 246, 245);
        this.doc.rect(0, 0, 130, 180, 'F');

        this.doc.addImage(logoLg, 40, 10, 175, 175)

        this.doc.setFillColor(202, 97, 45);
        this.doc.rect(0, 0, 18, 180, 'F');

        this.doc.addImage(logoSm, 5, 5, 8, 8)
        this.doc.setTextColor(20, 20, 20);

    },
    applyAward(template, opts) {

        const callback = this[template];
        if (typeof callback === 'function') {
            callback.call(this, opts);
        }
    },


    saljare(opts) {

        const amount = opts.amount;
        const amountText = numberWords.composeNumString(opts.amount)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9)
        this.doc.text(opts.month.toUpperCase(), 30, 25, {
            charSpace: "0.1"
        })

        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(42)
        this.doc.text(["Månadens", "Säljare"], 30, 40)


        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9);
        this.doc.text("TILLDELAT", 30, 120, {
            charSpace: "0.1"
        });

        this.doc.setFontSize(16);
        this.doc.text(opts.receiver, 30, 127);



        this.doc.setFillColor(238, 232, 222);
        this.doc.rect(30, 131, 66, 20, 'F');


        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(32);
        this.doc.text(this.numberWithSpaces(amount), 80, 145, {
            align: "right",
        });

        this.doc.setFontSize(16);
        this.doc.text("SEK", 82, 145);


        this.doc.setFont("poppinsSemiBold", "bold");

        this.doc.setTextColor(196, 188, 173);
        this.doc.setFontSize(5);
        this.doc.text(amountText.toUpperCase(), 90, 149, {
            charSpace: "0.1",
            align: "right",
        });



        this.doc.setTextColor(202, 97, 45);
        this.doc.setFontSize(9);
        this.doc.text("INTÄKT", 96, 157, {
            charSpace: "0.1",
            align: "right"
        });


    },
    medarbetare(opts) {
        const amount = opts.amount
        const amountText = numberWords.composeNumString(opts.amount)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9)
        this.doc.text(opts.month.toUpperCase(), 30, 25, {
            charSpace: "0.1"
        })

        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(42)
        this.doc.text(["Månadens", "Medarbetare"], 30, 40)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9);
        this.doc.text("TILLDELAT", 30, 120, {
            charSpace: "0.1"
        });

        this.doc.setFontSize(16);
        this.doc.text(opts.receiver, 30, 127);



        this.doc.setFillColor(238, 232, 222);
        this.doc.rect(30, 131, 35, 20, 'F');




        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(32);
        this.doc.text(amount.toString(), 50, 145, {
            align: "right",
        });



        this.doc.setFontSize(16);
        this.doc.text("ST", 52, 145);


        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setTextColor(202, 97, 45);
        this.doc.setFontSize(9);
        this.doc.text("ANTAL", 64.5, 157, {
            charSpace: "0.1",
            align: "right"
        });
    },
    nykundssaljare(opts) {
        const amount = opts.amount
        const amountText = numberWords.composeNumString(opts.amount)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9)
        this.doc.text(opts.month.toUpperCase(), 30, 25, {
            charSpace: "0.1"
        })

        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(38)
        this.doc.text(["Månadens", "Nykundssaljare"], 30, 39)


        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9);
        this.doc.text("TILLDELAT", 30, 120, {
            charSpace: "0.1"
        });

        this.doc.setFontSize(16);
        this.doc.text(opts.receiver, 30, 127);



        this.doc.setFillColor(238, 232, 222);
        this.doc.rect(30, 131, 35, 20, 'F');


        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(32);
        this.doc.text(amount.toString(), 50, 145, {
            align: "right",
        });



        this.doc.setFontSize(16);
        this.doc.text("ST", 52, 145);


        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setTextColor(202, 97, 45);
        this.doc.setFontSize(9);
        this.doc.text("ANTAL", 64.5, 157, {
            charSpace: "0.1",
            align: "right"
        });
    },
    leverans(opts) {
        const amount = opts.amount
        const amountText = numberWords.composeNumString(opts.amount)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9)
        this.doc.text(opts.month.toUpperCase(), 30, 25, {
            charSpace: "0.1"
        })

        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(42)
        this.doc.text(["Månadens", "Leverans"], 30, 40)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9);
        this.doc.text("TILLDELAT", 30, 120, {
            charSpace: "0.1"
        });

        this.doc.setFontSize(16);
        this.doc.text(opts.receiver, 30, 127);



        this.doc.setFillColor(238, 232, 222);
        this.doc.rect(30, 131, 35, 20, 'F');




        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(32);
        this.doc.text(amount.toString(), 50, 145, {
            align: "right",
        });



        this.doc.setFontSize(16);
        this.doc.text("ST", 52, 145);


        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setTextColor(202, 97, 45);
        this.doc.setFontSize(9);
        this.doc.text("ÖVERLEVERANSER", 64.5, 157, {
            charSpace: "0.1",
            align: "right"
        });
    },
    prestation(opts) {
        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9)
        this.doc.text(opts.month.toUpperCase(), 30, 25, {
            charSpace: "0.1"
        })

        this.doc.setFont("butlerRegular", "bold");
        this.doc.setFontSize(42)
        this.doc.text(["Månadens", "Prestation"], 30, 40)

        this.doc.setFont("poppinsSemiBold", "bold");
        this.doc.setFontSize(9);
        this.doc.text("TILLDELAT", 30, 120, {
            charSpace: "0.1"
        });

        this.doc.setFontSize(16);
        this.doc.text(opts.receiver, 30, 127);

        var lines = this.doc.splitTextToSize(opts.motivation, 160);  // Subtracting padding

        this.doc.setFont("poppinsRegular", "normal");
        this.doc.setFontSize(8);
        this.doc.text(lines, 30, 133, {
            lineHeightFactor: 1.4
        });

    },
    numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },


    create(template, opts = {
        amount: 0,
        receiver: "Name Surname",
        month: "Månad",
    }) {
        this.createMain();
        this.applyAward(template, opts)
        this.filename = "manadens_" + template + "-" + opts.month + "-" + opts.receiver.replace(" ", "-").trim();
    },
    save() {
        this.doc.save(this.filename.toLowerCase());
    },

}

function selectCurrentMonth() {
    const monthSelect = document.querySelector("#month-select");
    const date = new Date();
    const month = date.getMonth();
    monthSelect.selectedIndex = month - 1;
}

//eventlistener on doc ready
document.addEventListener("DOMContentLoaded", function () {
    const tip1 = document.querySelector("#tippy-form1");
    const tip2 = document.querySelector("#tippy-form2");
    const tip3 = document.querySelector("#tippy-form3");
    const tip4 = document.querySelector("#tippy-form4");
    const tip5 = document.querySelector("#tippy-form5");

    const monthSelect = document.querySelector("#month-select");

    const employeeForm = document.querySelector("#medarbetare");
    const sellerForm = document.querySelector("#saljare");
    const newCustomerSellerForm = document.querySelector("#nykundssaljare");
    const deliveryForm = document.querySelector("#leverans");
    const performanceForm = document.querySelector("#prestation");

    const forms = [employeeForm, sellerForm, newCustomerSellerForm, deliveryForm, performanceForm];

    selectCurrentMonth();

    forms.forEach(form => {
        form.addEventListener("submit", function (e) {
            e.preventDefault();
            const formData = new FormData(form);
            const data = Object.fromEntries(formData.entries());

            data.month = monthSelect.value;

            console.log(data);

            diplomaTemplate.create(form.id, data);
            diplomaTemplate.save();
        })
    })



    tippy(tip1, {
        content: "Antal kort som medarbetaren fått under månadens kortutdelningar",
        arrow: false,
        delay: [500, 200],
        maxWidth: 270,
    });
    tippy(tip2, {
        content: "Säljarens totala intäkt under månaden",
        arrow: false,
        delay: [500, 200],
        maxWidth: 270,
    });
    tippy(tip3, {
        content: "Totala antalet nykunder som säljaren har gjort affär med under månaden",
        arrow: false,
        delay: [500, 200],
        maxWidth: 270,
    });
    tippy(tip4, {
        content: "Antal kampanjer som överpresterat mot målsättning under månaden",
        arrow: false,
        delay: [500, 200],
        maxWidth: 270,
    });
    tippy(tip5, {
        content: "Ledningsgruppens motivation av månadens prestation",
        arrow: false,
        delay: [500, 200],
        maxWidth: 270,
    });




});
